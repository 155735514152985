import React, {
	Component
} from 'react';

import { Container, Table, Progress, Segment, Header } from 'semantic-ui-react'
export class Home extends Component {
	static displayName = Home.name;

	constructor(props) {
		super(props);
		this.state = { queue: [], loading: true, progress: {}, updateDone: true };
	}

	componentDidMount() {
		this.updateQueue(250);	
		this.updateProgress(250);
	}

	render() {
		let items = [];

		this.state.queue.forEach((item, index) => {
			items.push(
				<Table.Row key={item.fullRelativePath+item.serviceTarget.name+item.fileName}>
					<Table.Cell>
						{item.filePath}/{item.fileName}
					</Table.Cell>
					<Table.Cell>
						{this.progressBar(item)}
					</Table.Cell>
					<Table.Cell>
						{item.queueType}
					</Table.Cell>
					<Table.Cell>
						{item.failCount}
					</Table.Cell>
					<Table.Cell>
						{item.status}
					</Table.Cell>
					<Table.Cell>
						{item.serviceTarget.name}
					</Table.Cell>
				</Table.Row >
			);
		});

		return (
			<Segment inverted>
				<Header as='h3'>Items in queue: { this.state.queue.length }</Header>
				<Table celled fixed inverted>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Path</Table.HeaderCell>
							<Table.HeaderCell>Progress</Table.HeaderCell>
							<Table.HeaderCell>Type</Table.HeaderCell>
							<Table.HeaderCell>Failed</Table.HeaderCell>
							<Table.HeaderCell>Status</Table.HeaderCell>
							<Table.HeaderCell>Server</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{items}
					</Table.Body>
				</Table>
			</Segment>
		);
	}

	progressBar(file) {
		if (file.started) {
			const { progress } = this.state;
			const key = file.serviceTarget.name;
			if (progress[key] && progress[key][file.fullRelativePath]) {
				
				return (
					<Progress percent={this.toPercent(file.contentLength, progress[file.serviceTarget.name][file.fullRelativePath])} indicating progress />
				);
			}

		}
		return [];
	}

	updateQueue(delay) {
		clearTimeout(this.timer);

		this.timer = setTimeout(async () => {
			await this.populateQueue();
			this.updateQueue(1000);
		}, delay);
	}

	updateProgress(delay) {
		clearTimeout(this.progressTimer);

		this.progressTimer = setTimeout(async () => {
			await this.populateProgress();
			this.updateProgress( this.state.queue.length > 0 ? 500 : 2000);
		}, delay);
	}

	toPercent(x, y) {
		if (x > 0) {
			return Math.ceil((y / x) * 100)
		}

		return 0;
	}
	async populateQueue() {
		const response = await fetch('filesync/queue');
		const data = await response.json();
		this.setState({
			queue: data.filter(x => x !== null),
			loading: false
		});
	}

	async populateProgress()
	{
		const response = await fetch(`filesync/progress`);
		const data = await response.json();

		this.setState({
			progress: data
		});
	}
}